.post-card {
	width: 100%;
	min-height: 250px;
	max-height: 300px;
	margin-bottom: 50px;
	border-radius: 10px;
	font-size: 18px;
	overflow: hidden;
	cursor: pointer;
	a {text-decoration: none;}
	.post-card-inner {
		display: flex;
		.post-card-image {
			img {
				width: 400px;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		.post-card-text {
			font-size: 17px;
			padding: 20px;
			.post-card-title {
				font-size: 24px;
			}
			.post-card-date {
				color: #888;
				font-size: 13px;
			}
			.post-card-intro {
				font-size: 15px;
				margin-top: 20px;
				margin-bottom: 50px;
			}
		}
	}
}

@media (max-width: 990px) {
	.post-card {
		height: auto;
		.post-card-inner {
			.post-card-image {
				display: none;
			}
			.post-card-text {
				width: 100%;
			}
		}
	}	
}