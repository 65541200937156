.wrap-certificates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .certificate {
    cursor: pointer;
    width: 150px;
    margin: 20px 20px 0px 0px;
  }
}
