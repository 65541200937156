.menu-button-wrapper {
	display: flex;
	justify-content: flex-end;

	.icon-wrapper {
		.hamburger-lines {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 26px;
			width: 32px;
			z-index: 2;
			.line {
				display: block;
				height: 4px;
				width: 100%;
				border-radius: 10px;
			}
			.line1 {
				transform-origin: 0% 0%;
				transition: transform 0.4s ease-in-out;
			}
			.line2 {
				transition: transform 0.2s ease-in-out;
			}
			.line3 {
				transform-origin: 0% 100%;
				transition: transform 0.4s ease-in-out;
			}
		}
	}
	.wrap-list {
		.item-list {
			position: absolute;
			z-index: 1;
			width: 100%;
			top: 4vh;
			left: 0;
			display: flex;
			flex-direction: column;
			padding: 15px 0;
			border-radius: 0 0 5px 5px;
			text-align: center;
			user-select: none;
			transform: translateX(-50%) scale(0);
			transform-origin: center;
			transition: all 0.4s cubic-bezier(0.54, -0.10, 0.57, 0.57);
			a {
				position: relative;
				padding: 10px 25px;
				text-decoration: none;
				word-spacing: -4px;
				&:before {
					position: absolute;
					content: "";
					width: 0;
					height: 2px;
					bottom: -1px;
					left: 0;
					transition: 0.3s;
				}
				&:hover::before {
					width: 100%;
				}
			}
		}
	}
	.checkbox {
		position: absolute;
		display: block;
		height: 32px;
		width: 32px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;

		&:checked ~ .wrap-list .item-list {
			transform: translateX(0);
			opacity: 1;
			user-select: auto;
		}  
		&:checked ~ .icon-wrapper .hamburger-lines .line1 {
			transform: rotate(45deg);
		}
		&:checked ~ .icon-wrapper .hamburger-lines .line2 {
			transform: scaleY(0);
		}
		&:checked ~ .icon-wrapper .hamburger-lines .line3 {
			transform: rotate(-45deg);
		}
	}
}