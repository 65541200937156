.wrap-loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	.loader {
		width: 100px;
		height: 100px;
		position: relative;
		margin: auto;

		&::before, &::after {
			content: '';
			position: absolute;
		}
	}

	.loader-butterfly {
		transform-style: preserve-3d;
		transform: rotate3d(2, 1, -1, 90deg);

		&::before, &::after {
			border-radius: 50%;
			width: 50px;
			height: 50px;
			top: calc(50% - 8px);
			background: #b4134c;
			animation: 3s linear infinite;
		}

		&::before {
			left: 0;
			opacity: 0.7;
			border-top-right-radius: 0;
			transform-origin: right top;
			animation-name: flapL;
		}

		&::after {
			left: 50%;
			opacity: 0.5;
			border-top-left-radius: 0;
			transform-origin: left top;
			animation-name: flapR;
		}
	}
	.loader-message {
		font-family: 'Ubuntu Mono', monospace;
	}
}

@keyframes flapL {
	25%, 50% {
		transform: rotateY(-45deg)
	}
	12.5%, 37.5%, 65% {
		transform: rotateY(45deg);
	}
	0%, 100% {
		transform: rotateY(-30deg);
	}
}

@keyframes flapR {
	25%, 50% {
		transform: rotateY(45deg);
	}
	12.5%, 37.5%, 65% {
		transform: rotateY(-45deg);
	}
	0%, 100% {
		transform: rotateY(30deg);
	}
}